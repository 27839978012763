import React, {useState} from 'react';
import {Box, Card, Flex, Switch, Text} from "theme-ui";

const NetWageBox = ({netWage}) => {
    const [showSwissSalary, setShowSwissSalary] = useState(['showSwissSalary'])

    const handleChange = (e) => {
        setShowSwissSalary(!e.target.checked)
    }
    return (
        <Card variant="dark">
            <Flex sx={styles.content}>
                <Flex sx={styles.content.switchContainer}>
                    <Box sx={showSwissSalary ? {fontWeight: 'bold', color: 'white'} : {color: '#CCC'}}>CHF</Box>
                    <Switch onChange={handleChange}/>
                    <Box sx={!showSwissSalary ? {fontWeight: 'bold', color: 'white'} : {color: '#CCC'}}>EUR</Box>
                </Flex>
                <Flex sx={styles.content.salaryContainer}>
                    <Text>ca.</Text>
                    <Text variant="heading"
                          sx={styles.salary}>
                        {showSwissSalary ? netWage.swissNetSalary + ' CHF' : netWage.swissNetSalaryInEUR + ' EUR'}
                    </Text>
                    <Text>Nettolohn</Text>
                </Flex>
                <Text sx={{color: 'grey'}}>Monatlicher Nettolohn, exklusive Schweizer Krankenversicherung</Text>
                {!showSwissSalary ? <Text sx={{color: '#dfdfdf', fontSize: 18}}>Umrechnungskurs: 1 CHF = {netWage.exchangeRate} EUR</Text> : ''}
            </Flex>
        </Card>
    )
}

export default NetWageBox

const styles = {
    salary: {
        color: 'white',
        fontSize: 30,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingX: '.25em',
        '@media screen and (min-width: 400px)': {
            fontSize: 45,
        },
    },
    content: {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        switchContainer:
            {
                flex: '1 0 100%',
                flexWrap: 'wrap',
                marginBottom: '20px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                alignSelf: 'flex-start',
                '@media screen and (min-width: 992px)': {
                    flex: '1 0 106px',
                    flexWrap: 'nowrap',
                },
                'label': {
                    display: 'block',
                    width: 'auto',
                    margin: '0 8px',
                    '> div': {
                        margin: 0
                    }
                }
            },
        salaryContainer: {
            flexWrap: 'wrap',
            alignItems: 'baseline',
            flex: '1 0 100%'
        }
    },
};
