import {Box, Flex, Text} from "theme-ui";
import React from "react";

const WageCalcAccodordionTitle = ({label, value}) => {
    return (
        <Flex sx={{justifyContent: 'space-between', flexGrow: 1}}>
            <Box>{label}</Box>
            <Box>{value} CHF</Box>
        </Flex>
    )
}
export default WageCalcAccodordionTitle
