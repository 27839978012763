import {Box} from 'theme-ui';
import React from 'react';
import {
    Label,
    Input,
    Switch,
    Select,
    Button
} from 'theme-ui';
import axios from "axios";

const TaxForm = ({childToParent}) => {
    const kantone = [
        ['ZH', 'Zürich'],
        ['BE', 'Bern'],
        ['LU', 'Luzern'],
        ['UR', 'Uri'],
        ['SZ', 'Schwyz'],
        ['OW', 'Obwalden'],
        ['NW', 'Nidwalden'],
        ['GL', 'Glarus'],
        ['ZG', 'Zug'],
        ['FR', 'Freiburg'],
        ['SO', 'Solothurn'],
        ['BS', 'Basel-Stadt'],
        ['BL', 'Basel-Landschaft'],
        ['SH', 'Schaffhausen'],
        ['AR', 'Appenzell Ausserrhoden'],
        ['AI', 'Appenzell Innerrhoden'],
        ['SG', 'St.Gallen'],
        ['GR', 'Graubünden'],
        ['AG', 'Aargau'],
        ['TG', 'Thurgau'],
        ['TI', 'Tessin'],
        ['VD', 'Waadt'],
        ['VS', 'Wallis'],
        ['NE', 'Neuenburg'],
        ['GE', 'Genf'],
        ['JU', 'Jura']]
    kantone.sort((a,b)=> a[1].localeCompare(b[1]))
    kantone.unshift(['DE', 'Deutschland (Grenzgänger)'])
    const options = (array) => {
        return array.map((el, index) => <option key={index} value={el[0]}>{el[1]}</option>)
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        const payload = {
            "monthlyGrossWage": parseInt(e.target.monthlyGrossWage.value),
            "age": parseInt(e.target.age.value),
            "isChurchTaxLiable": e.target.isChurchTaxLiable.checked,
            "isMarried": e.target.isMarried.checked,
            "children": parseInt(e.target.children.value),
            "state": e.target.state.value
        }

        axios.post('https://api.nettolohnrechner.ch/v1/payrolls/swissSalary', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(r => {
                const entries = Object.entries(r.data).map(entry => {
                    if (typeof entry[1] == 'string') entry[1] = parseFloat(entry[1])
                    entry[1] = (entry[0] !== 'exchangeRate') ? entry[1].toFixed(2) : entry[1];
                    return [entry[0], entry[1]]
                })
                const formattedData = Object.fromEntries(entries)
                childToParent(formattedData)
            })
            .catch(e => console.log(e))
    }

    return (
        <Box as="form" onSubmit={handleSubmit} sx={styles.form}>
            <Input sx={styles.input} type="number" name="monthlyGrossWage" id="monthlyGrossWage" mb={3}
                   defaultValue={6000} placeholder={6000}/>
            <Label sx={styles.label} htmlFor="monthlyGrossWage">Monatlicher Bruttolohn in CHF</Label>
            <Input sx={styles.input} type="number" name="abe" id="age" mb={3} defaultValue={30} placeholder={30}/>
            <Label sx={styles.label} htmlFor="age">Alter in Jahren</Label>
            <Select sx={styles.select} name="children" id="children" mb={3} defaultValue={0}>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
            </Select>
            <Label sx={styles.label} htmlFor="children">Kinder</Label>

            <Select sx={styles.select} name="state" id="state" mb={3} defaultValue={'Deutschland'}>
                {options(kantone)}
            </Select>
            <Label sx={styles.label} htmlFor="state">Wohnort</Label>

            <Box sx={styles.switchContainer}>
                <Switch
                    id='isMarried'
                    sx={styles.switchContainer.switch}
                    label="Verheiratet"
                    name="isMarried"
                />
            </Box>
            <Box sx={styles.switchContainer}>

                <Switch
                    label="Kirchenmitglied"
                    name="isChurchTaxLiable"
                    id='isChurchTaxLiable'
                    sx={styles.switchContainer.switch}
                />
            </Box>
            <Button variant="submit" sx={styles.submit}>Jetzt Nettolohn berechnen</Button>
        </Box>
    );
}

export default TaxForm
const styles = {
    label: {
        marginBottom: '1em',
        '@media screen and (min-width: 992px)': {
            marginBottom: 0,
            alignItems: 'center'
        }
    },
    input: {
        marginBottom: 0,
        fontSize: 20
    },
    select: {
        background: 'transparent',
        marginBottom: 0,
        fontSize: 20,
        'option': {
            color: 'black'
        }
    },
    submit: {
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    form: {
        display: 'grid',
        gridColumnGap: '30px',
        gridRowGap: '.25em',
        '@media screen and (min-width: 992px)': {
            gridTemplateColumns: 'minmax(160px, 1fr) 2fr',
            gridRowGap: '1em',

        },
        '> label': {
            fontSize: '20px'
        },
        '> div': {
            marginBottom: 0
        }
    },
    switchContainer: {
        gridColumn: '1 / 2',
        '@media screen and (min-width: 992px)': {
            gridColumn: '1 / 3'
        },
        label: {
            fontSize: 20,
            display: 'grid',
            gridColumnGap: '30px',
            marginBottom: '1em',
            '@media screen and (min-width: 992px)': {
                marginBottom: 0,
                gridTemplateColumns: 'minmax(160px, 1fr) 2fr',
            }
        },
        switch: {
            backgroundColor: '#999',
            height: 29,
            width: 55,
            marginRight: 0,
            'input:checked ~ &': {
                backgroundColor: 'primary',
            }
        },
        'div > div': {
            height: 25,
            width: 25
        }
    },
}