import React from 'react';
import {Box, Text} from "theme-ui";
import Accordion from "./accordion/accordion";
import WageCalcAccodordionTitle from "./accordion/wageCalcAccodordionTitle";

const WageCalcTable = ({netWage}) => {
    let wageCalcAccordionData = [
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label="Monatlicher Bruttolohn" value={netWage.monthlyGrossWage}>
                </WageCalcAccodordionTitle>
            ),
            contents: (
                <div>
                    Ihr monatliches Bruttogehalt in Schweizer Franken.
                </div>
            )
        },
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label='Quellensteuer' value={netWage.withholdingTax}/>
            ),
            contents: (
                <div>
                    Für Wochenaufenthalter hängt die Quellensteuer vom Einkommen, Familienstand und Kanton ab. Grenzgänger zahlen einen pauschalen Quellensteuersatz von 4,5 %.
                </div>
            )
        },
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label='Pensionskasse' value={netWage.pensionFundContribution}/>
            ),
            contents: (
                <div>
                    Die Höhe der Pensionskassenbeiträge variiert je nach Eintrittsalter, Geschlecht, dem jeweiligen Firmenvertrag und dem sogenannten koordinierten Lohn. In der Regel wird der Beitrag zur Hälfte vom Grenzgänger und zur Hälfte vom Arbeitgeber getragen.
                </div>
            )
        },
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label="AHV/IV/EO-Beitrag" value={netWage.ahvContribution}>
                </WageCalcAccodordionTitle>
            ),
            contents: (
                <div>
                    Der Grenzgänger und sein Arbeitgeber zahlen je einen Beitrag von 5,275% des in der Schweiz verdienten Lohnes an die Alters- und Hinterlassenenversicherung (AHV).
                </div>
            )
        },
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label='ALV-Beitrag' value={netWage.alvContribution}/>
            ),
            contents: (
                <div>
                    Bis zu einem Jahreseinkommen von 148.200 CHF werden die Beiträge zur Arbeitslosenversicherung (ALV) je zur Hälfte vom Grenzgänger und vom Arbeitgeber getragen. Ab einem Jahreseinkommen von 148.200 CHF beträgt der Beitrag für den Grenzgänger 0,5%.
                </div>
            )
        },
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label='NBUV-Beitrag' value={netWage.nbuvContribution}/>
            ),
            contents: (
                <div>
                    Der Arbeitgeber zahlt die Berufsunfallversicherung, während der Arbeitnehmer die Nichtbetriebsunfallversicherung (NBUV) zu tragen hat. Der Beitragssatz des Grenzgängers beträgt derzeit 1,086 % - 1,99 % und variiert je nach Beruf.
                </div>
            )
        },
        {
            isExpanded: false,
            title: (
                <WageCalcAccodordionTitle label='KTG-Beitrag' value={netWage.ktgContribution}/>
            ),
            contents: (
                <div>
                    Der Arbeitgeber schließt eine Krankentagegeldversicherung (KTG) für den Arbeitnehmer ab. Der Beitrag beträgt - je nach Betriebsvereinbarung - ca. 0,45 % - 1,5 % des Bruttoeinkommens.
                </div>
            )
        },
    ]
    return (
        <Box variant="cards.shadow" sx={styles.cardShadow}>
            <Box><Text variant="heading">Schweizer Lohnabrechnung</Text></Box>
            <Accordion items={wageCalcAccordionData}/>
        </Box>
    )
}

export default WageCalcTable

const styles = {
    cardShadow: {
        my: 40,
    }
};
