import React from 'react';
import { StickyProvider } from '../contexts/app/app.provider';
import { VideoProvider } from '../contexts/video/video.provider';
import Layout from '../components/layout';
import SEO from "../components/seo";

import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'typeface-dm-sans';
import Calculator from "../sections/calculator";

const IndexPage = () => {
  return (
    <StickyProvider>
      <VideoProvider>
        <Layout>
          <SEO
            description="Sie möchten wissen, was Sie in der Schweiz als Wochenaufenthalter in Zukunft verdienen? Jetzt hier Nettolohn berechnen."
            title="Brutto Netto Rechner Schweiz für Wochenaufenthalter"
          />
          <Calculator />
        </Layout>
      </VideoProvider>
    </StickyProvider>
  );
};

export default IndexPage;
