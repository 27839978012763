import {
    Box,
    Flex,
    Container,
    Heading,
    Text, Grid, Card, Button
} from 'theme-ui';

import React, {useState} from 'react';
import { Element, scroller } from "react-scroll";

import TaxForm from "../components/tax-form";
import NetWageBox from "../components/netWageBox";
import WageCalcTable from "../components/wageCalcTable";
import Link from "gatsby-link";

const Calculator = () => {
    const [data, setData] = useState('');
    const [isRequestFinished, setIsDataDisplayed] = useState(false)

    const childToParent = (childData) => {
        setData(childData);
        setIsDataDisplayed(true)
        scroller.scrollTo('netWageBox', {
            duration: 1000,
            delay: 100,
            smooth: true,
            offset: 50,
        });
    }


    return (
        <div>
            <Box as="section" id="banner" sx={styles.banner}>
                <Box sx={styles.banner.after}/>

                <Container sx={styles.banner.container}>
                    <Flex sx={styles.banner.row}>
                        <Box sx={styles.banner.col}>
                            <Box sx={styles.banner.content}>

                                {isRequestFinished
                                    ? <Box sx={{zIndex: 1}}>
                                        <Heading as="h1" sx={styles.headingResult}>
                                            Ihre Nettolohnberechnung
                                        </Heading>
                                        <Text as="p" sx={{fontWeight: 'bold', fontSize: 22}}>
                                            Anhand Ihrer Angaben ergibt sich die unten folgende Schweizer Lohnabrechnung.
                                        </Text>
                                    </Box>
                                    :
                                    <Box>
                                        <Heading as="h1" sx={styles.heading1}>
                                            Brutto-Netto-Rechner Schweiz
                                        </Heading>
                                        <Text as="p" sx={styles.heading2}>
                                            Grenzgänger oder Wochenaufenthalter?
                                        </Text>
                                        <Text as="p" sx={styles.heading3}>
                                            Geben Sie hier rechts Ihren monatlichen Bruttolohn ein und berechen Sie Ihren
                                            persönlichen Nettolohn!
                                        </Text>
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box sx={styles.banner.col}>
                            <Box sx={styles.banner.formContainer}>
                                <TaxForm childToParent={childToParent}/>
                                <Element name="netWageBox"/>
                            </Box>
                        </Box>
                    </Flex>
                </Container>
            </Box>
            {isRequestFinished
                ? <Box as="section" id="result" sx={styles.calculatorResult}>
                    <Container>
                        <Grid sx={styles.responsiveGrid}>
                            <NetWageBox netWage={data}/>
                            <Card variant='borderPrimary'>
                                <Text sx={{marginBottom: 3}} as="h2">
                                    Nettolohnberechnung für Grenzgänger
                                </Text>
                                <Text as="p">
                                    Achtung! Für Grenzgänger gilt das Doppelbesteuerungsabkommen. Sie müssen also auch Steuern in Deutschland entrichten. Berechnen Sie jetzt im nächsten Schritt Ihre deutsche Steuerpflicht!</Text>
                                <Link to={'/'}>
                                    <Button sx={styles.button}>
                                        Deutsche Steuerpflicht berechnen
                                    </Button>
                                </Link>
                            </Card>
                        </Grid>
                    </Container>
                    <Container>
                        <Grid sx={styles.responsiveGrid}>
                            <WageCalcTable netWage={data}/>
                        </Grid>
                    </Container>
                </Box>
                : ''
            }
        </div>
    );
}

export default Calculator;

const styles = {
    banner: {
        marginBottom: '150px',
        overflowX: 'hidden',
        paddingTop: ['100px', '120px'],
        //backgroundImage: `url(${mountainMan})`,
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        position: 'relative',
        overflow: 'visible',
        container: {
            position: 'relative',
            maxWidth: ['100%', null, null, null, null, '1240px', '1440px'],
        },
        row: {
            flexWrap: 'wrap',
            display: 'flex',
            marginLeft: '-15px',
            marginRight: '-15px',
            flexDirection: 'column',
            '@media screen and (min-width: 992px)': {
                flexDirection: 'row'
            }
        },
        col: {
            pl: '15px',
            pr: '15px',
            flex: ['1 1 100%', null, '0 0 50%'],
        },
        signup: {
            display: 'flex',
            gridGap: 2,
            alignItems: 'center',
            mt: ['30px'],
            mb: ['50px'],
            button: {
                minHeight: ['45px', null, null, 60, 50, null, 60],
                fontSize: ['14px', '14px', '16px'],
            },
        },
        formContainer: {
            padding: 4,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            background: 'rgba(0,0,0, .6)',
            color: 'white',
            position: 'relative',
            '@media(max-width: 991px)': {
                marginTop: 4
            },
        },
        after: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: '#e3dedd',
            top: 0,
            zIndex: 0
            // background: 'linear-gradient(0deg, rgba(14,29,43,0.8) 0%, rgba(255,255,255,0) 100%)',
        }
    },
    responsiveGrid: {
        gridTemplateColumns: '1fr',
        '@media(min-width: 992px)': {
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: '30px'
        }
    },
    heading1: {
        fontSize: 45,
        lineHeight: '125%',
        fontWeight: 'bold',
        marginBottom: 4,
        marginTop: 4,
        '@media screen and (max-width: 991px)': {
            fontSize: 28,
            marginTop: 0,
        },
    },
    heading2: {
        fontWeight: 'bold',
        fontSize: 34,
        lineHeight: '135%',
        marginBottom: 3,
        '@media screen and (max-width: 991px)': {
            fontSize: 20,
        },
        '@media (min-width: 991px) and (max-width: 1440px)': {
            fontSize: 22,
        },
    },
    heading3: {
        fontWeight: 'bold',
        fontSize: 26,
        lineHeight: '135%',
        marginBottom: 4,
        '@media screen and (max-width: 991px)': {
            fontSize: 18,
            marginBottom: 0,
        },
        '@media (min-width: 991px) and (max-width: 1440px)': {
            fontSize: 20,
            marginBottom: 0,
        },
    },
    headingResult: {
        fontSize: 45,
        '@media screen and (max-width: 991px)': {
            fontSize: 28,
            marginBottom: 2,
            marginTop: 0,
        },
        lineHeight: '125%',
        fontWeight: 'bold',
        marginBottom: '40px',
        marginTop: '100px',
    },
    button: {
        outline: 'none',
        display: 'inline-block',
        fontWeight: 'bold',
        color: 'primary',
        bg: 'primaryLight',
        cursor: 'pointer',
        transition: '500ms',
        fontSize: ['14px', null, null, '16px'],
        letterSpacing: '-0.16px',
        marginTop: 3,
        padding: '10px 19px',
        borderRadius: '5px',
        textDecoration: 'none',
        '&:hover': {
            bg: 'primary',
            color: '#fff',
        },
    }
};
